@import './../../../styles/settings';

.customDropdown {
  display: flex;
  position: relative;
  max-width: 100%;

  &Content {
    position: relative;
    width: 100%;
  }
  .formLabel {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 22px;
    color: $text-light-color;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    margin-left: 15px;
    width: max-content;
    transform: translate(0, -50%);
    transition: all 0.4s ease-out;
  }
  .customDropdown:focus ~ .formLabel,
  .customDropdown:not(:placeholder-shown) ~ .formLabel {
    top: 8px;
    font-size: 10px;
  }
  .fieldContainer {
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  //.formLabel {
  //  display: flex;
  //  align-items: center;
  //  margin-bottom: 0;
  //  position: absolute;
  //  top: 50%;
  //  transition: all 0.4s ease-out;
  //  transform: translate(0, -50%);
  //  cursor: pointer;
  //  font-size: 1.4rem;
  //  font-weight: 400;
  //  color: $black;
  //}

  //.labelSpaceStartDefault {
  //  left: 15px !important;
  //}

  .labelSpaceStart {
    left: 20px !important;
  }

  .labelSpaceStartWithIconDefault {
    left: 35px !important;
  }

  .labelSpaceStartWithIcon {
    left: 55px !important;
  }

  .labelSpaceEndWithIconDefault {
    padding-right: 35px !important;
  }

  .labelSpaceEndWithIcon {
    padding-right: 55px !important;
  }

  .customField {
    cursor: pointer;
    width: 100%;
    display: block;
    border-radius: 10px;
    color: $black;
    font-size: 1.6rem;
    padding: 10px 20px;
    padding-top: 23px;
    border: 2px solid $border-color;
    box-shadow: none;
    font-weight: 400;
    box-sizing: border-box;
    background-color: $white;
    &::placeholder {
      opacity: 0;
      cursor: pointer;
    }

    &:read-only {
      pointer-events: none;
      cursor: default;
    }

    &:focus {
      outline: none;
      color: $black;
      border-color: $primary-color;
    }
  }

  .customFieldDefault {
    @extend .customField;
    line-height: 1.5;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    border-radius: 8px;
    color: $black;
    padding: 10px 30px 10px 16px;
    border: 1px solid $border-color;
    background-color: $white;
    box-shadow: none;

    &::placeholder {
      opacity: 0;
      cursor: pointer;
    }

    &:read-only {
      pointer-events: none;
      cursor: default;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      //background-color: $disable-color;
      color: $black;
      border: 1px solid $primary-color;
      box-shadow: 1px $primary-color;
      //box-shadow: 0 0 0 1px rgb($dark-gray-color, 1);
    }
  }

  //.customField:focus~.formLabel,
  //.customField:not(:placeholder-shown)~.formLabel {
  //  top: 10px;
  //  font-size: 1.2rem;
  //}

  //.customFieldDefault:focus~.formLabel,
  //.customFieldDefault:not(:placeholder-shown)~.formLabel {
  //  top: 10px;
  //  font-size: 1rem;
  //}

  .customField.iconSpaceStart {
    padding-left: 55px !important;
  }

  .customField.iconSpaceEnd {
    padding-right: 55px !important;
  }

  .customFieldDefault.iconSpaceStart {
    padding-left: 35px !important;
  }

  .customFieldDefault.iconSpaceEnd {
    padding-right: 35px !important;
  }

  .icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  .icon.iconStart {
    left: 20px;
  }

  .icon.iconEnd {
    right: 20px;
  }

  .iconDefault.iconStart {
    left: 15px !important;
  }

  .iconDefault.iconEnd {
    right: 15px !important;
  }

  &Body {
    top: 35px;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 0;
    min-height: max-content;
    //overflow-x: hidden;
    //overflow-y: auto;
    background-color: $white;
    border-radius: 0 0 8px 8px;
    border: 1px solid $primary-color;
    border-top: none;
    opacity: 1;
    z-index: 1;
    // padding: 5px 10px 20px 10px;
    transition: all 500ms ease-out;
    // bottom: 100%;

    &InnerBox {
      width: 100%;
      max-height: 225px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 10px;
    }

    &--mobile-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 10px;
      //border-bottom: 1px solid $disable-color;

      h1 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 800;
        margin: 0;
        color: $disabled-color;
      }
    }

    .selected {
      border-radius: 10px;
      border-color: $primary-color;
      background-color: $primary-color;
      color: $white;

      & + .customDropdownBodyItem {
        border-top-color: transparent;
      }
    }

    .mobile-input {
      padding: 10px 5px;
      margin-left: 0;
      border: 1px solid $disabled-color;
      box-shadow: 0 3px 8px $disabled-color;
    }

    &Item {
      display: flex;
      flex-direction: row;
      color: $black;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      padding: 15px 15px;
      //border-bottom: 1px solid $light-gray-color;

      span {
        width: 100%;
        display: block;
        transition: all 0.2s ease-in;
      }

      &:hover {
        cursor: pointer;
        border-radius: 10px;
        border-color: $primary-color;
        background-color: $primary-color;
        color: $white;

        & + .customDropdownBodyItem {
          border-top-color: transparent;
        }
      }

      &:first-child {
        border-top: none;
      }

      &:last-child {
        //border-bottom: 1px solid $light-gray-color;
      }
    }
  }

  &BodyDefault {
    @extend .customDropdownBody;
  }
}

.customDropdownBody {
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 2px;

    &:hover {
      width: 1rem;
      height: 1rem;
      background: #9f9f9f;
    }
  }
}
.closeIcon {
  display: block;
  position: absolute;
  top: 50%;
  right: 45px;
  justify-content: end;
  transform: translate(0, -50%);
  cursor: pointer;
}
.open {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 105px;
  left: 0;
  background-color: $white;
  //z-index: 7;
}

.closed {
  position: relative;
  height: auto;
  width: 100%;
}

.removeGutter {
  .customDropdownBody {
    margin: 0 0 !important;
    padding: 5px 20px 20px 20px !important;
  }
}

/** responsive styles */

@include respondTo($xs) {
  .open {
    position: relative;
    height: auto;
    //z-index: 1;
    top: 0;
  }
}

@include respondTo($md) {
  .customDropdown {
    &--mobile-title {
      display: none;
    }

    &Body {
      top: 100%;
      // bottom: 100%;
      position: absolute;
      margin: 0 20px;
      //max-height: 250px;
      min-height: 0;
      //overflow-x: hidden;
      //overflow-y: auto;
      max-height: 250px;
      z-index: 4;
      padding: 5px 30px 20px 30px;
      //box-shadow: 0 10px 25px 10px rgba($black, 0.2);

      &InnerBox {
        margin-top: 0;
        //overflow-y: auto;
        //overflow-x: hidden;
      }
    }

    .dropUp {
      bottom: 100% !important;
      top: unset !important;
      border-top: 1px solid $primary-color !important;
      border-radius: 8px 8px 0 0 !important;
      border-bottom: none !important;
    }

    &BodyDefault {
      background-color: $white;
      margin: 0;
      top: calc(79% + 4px);
      padding: 5px 10px 10px 10px;
      border: 1px solid $primary-color;
      border-top: none;
      z-index: 7;
      width: 100%;
      left: 0;
      border-radius: 0 0 8px 8px;

      .customDropdownBodyItem {
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 10px;
      }
    }
  }

  .open {
    position: relative;
    height: auto;
    //z-index: 1;
    top: 0;
    width: 100%;

    .customDropdownContent {
      padding: 0;
    }
  }
}

@include respondTo($lg) {
  //.customDropdownBodyInnerBox{
  //  height: 100%;
  //}
  .custom-dropdown {
    &--input {
      margin-left: -5px;
    }
  }
}
