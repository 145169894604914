@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Black'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Black.woff') format('woff');
  //src: url("../fonts/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-BlackItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-BoldItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Bold'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-ExtraLight'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-ExtraLightItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff')
      format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Italic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Light'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-LightItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Regular'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-SemiBold'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-SemiBoldItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff')
      format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Black'),
    url('../fonts/montserrat/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-BlackItalic'),
    url('../fonts/montserrat/Montserrat-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Bold'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-BoldItalic'),
    url('../fonts/montserrat/Montserrat-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraBold'),
    url('../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraBoldItalic'),
    url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraLight'),
    url('../fonts/montserrat/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraLightItalic'),
    url('../fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Italic'),
    url('../fonts/montserrat/Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Light'),
    url('../fonts/montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Medium'),
    url('../fonts/montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-LightItalic'),
    url('../fonts/montserrat/Montserrat-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-MediumItalic'),
    url('../fonts/montserrat/Montserrat-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Regular'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-SemiBold'),
    url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-SemiBoldItalic'),
    url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Thin'),
    url('../fonts/montserrat/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ThinItalic'),
    url('../fonts/montserrat/Montserrat-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
