@import 'src/styles/settings';

.widthBtn {
  width: fit-content !important;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}
