@import 'src/styles/settings';

.range {
  position: absolute;
  height: 2px;
  background: $primary-color;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  //bottom: 20%;
}

.height {
  height: 60px;
}

.main {
  position: relative;
  width: 100%;
  top: 30px;
}

.minHours {
  margin-left: 12px;
}

.energyMain {
  @extend .main;
  width: 103%;
}

.inputRange {
  display: block;
  -webkit-appearance: none;
  background-color: #bdc3c7;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  //position: relative;
}

.inputRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 5px solid $primary-color;
  background: $white;

  position: relative;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s ease-in-out;
}

.inputRange::-webkit-slider-thumb:active {
  transform: scale(1);
}

.inputRange::-ms-fill-lower {
  background: blue;
}

.step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 12px;
  padding: 0 2px 0 0;
  right: 0;
}

@include respondTo($md) {
  .step {
    width: 110%;
    position: absolute;
    top: 12px;
    left: -20px;
    padding: 0 2px 0 0;
    right: 0;
  }
}

@include respondTo($lg) {
  .main {
    top: 0;
  }
}
