@import './../../../styles/settings';

.imageUpload {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: relative;
}
.imageSize {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50%;
}
.uploadIcon {
  position: absolute;
  left: 70%;
  height: 48px;
  top: 64%;
  width: 48px;
  border-radius: 50%;
  background: $primary-color-50;
  display: flex;
  justify-content: center;
  border: 5px solid $white;
  align-items: center;
}
