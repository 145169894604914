@import 'src/styles/settings';
.content {
  padding: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.contentTopThree {
  @extend .content;
}
.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-right: 5px;
  width: fit-content;
}
.inactiveTag {
  background: $danger-color;
  color: $white;
  border-radius: 20px;
  padding: 4px 16px;
  margin-right: 5px;
}
.contentCompany {
  @extend .content;
  padding: 12px;
}
.card {
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid $gray-200-color;
  height: fit-content;
  background: $white;
}
.yellowBox {
  background: $orange-color-50;
  border-radius: 10px;
  padding: 12px;
}
.popUpbutton {
  height: fit-content;
}
.webView {
  display: none;
}
.opacity {
  opacity: 0.4;
  position: relative;
}
.PopUp {
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: $white;
  padding: 12px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  top: 300%;
}
.link {
  color: $primary-color;
  cursor: pointer;
}
.introTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.statusCard {
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 24px 20px;
}
.alertBox {
  border-radius: 10px;
  padding: 24px 32px;
  border: 1px solid $danger-color;
  background: $danger-color-50;
}
.addBtn {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: 15rem !important;
  height: 38px;
}
.btn {
  border-radius: 20px;
  padding: 4px 16px;

  border: 1px solid $primary-color-100;
  background: $white;
}
.borderClass {
  border-left: 1px solid $gray-200-color;
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}
.whiteBtn {
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  height: 20px;
}
.screening {
  border-radius: 10px;
  background: $success-color-50;
  padding: 4px 16px;
}
.activeBtn {
  @extend .btn;
  border: 1px solid $primary-color;
  background: $primary-color-50;
}
.filter {
  border-top: 1px solid $gray-200-color;
  border-bottom: 1px solid $gray-200-color;
}
.borderBottom {
  border-bottom: 1px solid $gray-200-color;
}
.cardProfile {
  @extend .card;
}
@include respondTo($lg) {
  .payableTag {
    //margin-right: 24px;
  }
  .PopUp {
    width: 620px;
    left: 37%;
    top: 120%;
    padding: 24px;
  }
}

@include respondTo($xl) {
  .rightSide {
    width: 60%;
  }
  .card {
    padding: 43px 32px;
    height: 280px;
  }
  .cardProfile {
    height: 350px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .webView {
    display: block;
  }
  .mobileView {
    display: none;
  }
  .PopUp {
    left: 45%;
  }
  .yellowBox {
    padding: 50px 24px 24px 24px;
  }
  .widthBox {
    width: 25%;
  }
  .introTitle {
    flex-direction: row;
  }

  .content {
    padding: 70px;
    flex-direction: row;
  }
  .contentTopThree {
    padding: 40px 70px 70px 70px !important;
  }
  .contentCompany {
    padding: 70px 250px;
  }
  .leftSide {
    width: 40%;
    margin-right: 60px;
  }
}

.width100 {
  width: 100%;
}
