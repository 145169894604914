@import 'src/styles/settings';

.card {
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid;
  height: fit-content;
  background: $white;
}
.topCard {
  @extend .card;
}
.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.discription {
  display: flex;
  flex-direction: column;
}
.Withoutdiscription {
  @extend .discription;
}
.rotateArrow {
  transform: rotate(180deg);
  transition-duration: 1s;
  transition-property: transform;
}
.rotateBack {
  transition-duration: 1s;
  transition-property: transform;
}
.blueTag {
  width: fit-content;
  padding: 4px 16px;
  border-radius: 8px;
  display: flex;
  background: $info-50-color;
}
.redTag {
  display: flex;
  background: $danger-color-50;
  width: fit-content;
  padding: 4px 16px;
  border-radius: 8px;
}
//.modal{
//  padding: 60px;
//  width: 700px;
//}
.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}
.widthButton {
  width: fit-content;
}
.OutlinedBtn {
  color: $text-dark-color !important;
  border: 3px solid $primary-color-50 !important;
  background: none !important;
  width: fit-content !important;
}

.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
}
.Loadingcard {
  border: 1px solid $gray-200-color;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
}
.greenBox {
  border-radius: 10px;
  border: 1px solid $success-color;
  display: flex;
  background: $success-color-50;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}
.yellowBox {
  border-radius: 10px;
  background: $orange-color-50;
  display: flex;
  justify-content: space-between;
  border: 1px solid $warning-color;
  padding: 24px 32px;
}
.borderClass {
  border-left: 1px solid $gray-200-color;
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}
.borderClassOrg {
  display: flex;
}
.resourcePic {
  height: 100px;
  width: 100px;
  border-radius: 16px;
}
.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
  width: 250px !important;
}
.disabledBtn {
  @extend .outlineBtn;
  background: $light-color !important;
  height: 50px !important;
}
.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-right: 5px;
  width: fit-content;
}

.inactiveTag {
  background: $danger-color;
  color: $white;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
}

.outlineProgressBtn {
  @extend .outlineBtn;
  width: 100% !important;
  height: 50px !important;
}
.widthBtn {
  width: 250px !important;
  height: 50px !important;
}
.viewBtn {
  width: 160px !important;
}
.displayBlock {
  display: block;
  transition-duration: 1s;
  transition-property: display;
}
.displayNone {
  display: none;
  transition-duration: 1s;
  transition-property: display;
}
.profileStatus {
  background: $success-color-50;
  border-radius: 10px;
  padding: 16px 20px;
  transition: padding-top 1s;
}
.openProfileStatus {
  @extend .profileStatus;
  padding: 5px 20px 16px 20px;
  transition: padding-top 1s;
}
.profilePic {
  width: 62px;
  height: 62px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.link {
  color: $primary-color;
  cursor: pointer;
}
.nonPublished {
  padding: 4px 16px;
  border-radius: 10px;
  background: $orange-color-50;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 5px;
}
.whiteBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
  width: fit-content;
}
.mobilewhiteBtn {
  @extend .whiteBtn;
  width: 100%;
}
.publishedCard {
  @extend .nonPublished;
  background: $success-color-50;
}
.cardProfile {
  @extend .card;
}
.topthreeCard {
  @extend .cardProfile;
  border-color: $gray-200-color;
}
.jobsCard {
  @extend .topthreeCard;
  height: unset !important;
}
.iconBox {
  display: flex;
}
.widthCommitment {
  width: 30%;
}
.widthDescription {
  width: 100%;
}
.elipsisDes {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.borderLeft {
  @extend .borderClass;
  border-right: none;
}

@include respondTo($md) {
  .elipsisDes {
    width: 86%;
  }
  .borderClassOrg {
    border-left: 1px solid $gray-200-color;
    border-right: 1px solid $gray-200-color;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nonPublished {
    margin-top: 0;
  }
  .mobilewhiteBtn {
    width: fit-content;
  }
  .payableTag {
    margin-right: 24px;
  }
  .tagBox {
    display: flex;
  }
  .displayFlex {
    display: flex;
  }
  .widthCommitment {
    width: fit-content;
  }
  .borderClass {
    padding-right: 15px;
    padding-left: 15px;
  }
  .borderLeft {
    @extend .borderClass;
    border-right: none;
  }
}
@include respondTo($xl) {
  .widthDescription {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
  }
  .payableTag {
    margin-right: 24px;
  }

  .cardProfile {
    height: 350px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .topthreeCard {
    height: unset !important;
  }
  .jobsCard {
    height: unset !important;
  }
  .card {
    height: 280px;
  }
  .insideCard {
    display: flex;
    align-items: center;
  }
  .discription {
    flex-direction: row;
    align-items: end;
  }
  .Withoutdiscription {
    justify-content: end;
  }
  .card {
    padding: 24px 32px;
  }
}

.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}
