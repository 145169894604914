@import 'src/styles/settings';

.card {
  border-radius: 10px;
  padding: 24px 32px;
  border: 1px solid $gray-200-color;
  height: fit-content;
  margin-bottom: 100px;
}
.assesmentClock {
  left: 90%;
  top: 135px;
}
.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  width: fit-content;
}
.widthButton {
  width: fit-content;
}
.whiteBtn {
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  height: 20px;
}
.assesmentAnswers {
  border-radius: 50% !important;
}
.content {
  display: flex;
  justify-content: center;
  padding: 12px;
}
.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}
.borderTop {
  border-top: 1px solid $light-color;
  padding-top: 25px;
}

.OutlinedBtn {
  color: $text-dark-color !important;
  border: 3px solid $primary-color-50 !important;
  background: none !important;
  width: fit-content !important;
}
.borderClass {
  border-left: 1px solid $gray-200-color;
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.paddingRight {
  padding-right: 100px;
}
.marginTop {
  margin-top: 30px;
}
.marginLeft {
  margin-left: 117px;
}
.contentAssessment {
  padding: 12px;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include respondTo($lg) {
  .assesmentClock {
    position: fixed;
  }
  .content {
    margin-top: 70px;
  }
  .contentAssessment {
    padding: 60px 270px 100px 260px;
  }
  //.modal{
  //  padding: 60px;
  //  width: 700px;
  //}
  .card {
    width: 70%;
  }
}
