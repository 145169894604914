@import '../../../styles/_settings.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading_spinner {
  width: 100px;
  height: 100px;
  border: 15px solid white;
  border-top: 15px solid $primary-color;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-top: -50px;
  margin-left: -50px;
}

.spinner_container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 20vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* set a high z-index value */
}

.app_while_loading {
  opacity: 0.5;
}
