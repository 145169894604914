@import 'src/styles/settings';

.button {
  margin: 0;
  height: 5.5vh;
  padding: 8px 16px;
  overflow: hidden;
  outline: none;
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;

  &:focus,
  &:active {
    outline: none;
    border-color: $primary-color;
    transition: all 0.5s ease-in-out;
  }
}

.dateText {
  font-size: 1.4rem;
}

.icon {
  margin-right: 5px;
}

.outward {
  font-size: 1.2rem;
  line-height: 1.2;
  position: absolute;
  top: 5px;
}

@include respondTo($md) {
  .button {
    height: 6.5vh;
    width: 92%;
  }
}
