@import './../../../styles/settings';

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
}
.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $info-50-color transparent;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: $info-50-color;
  color: $black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 120%;
  right: -70px;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@include respondTo($md) {
  .tooltip .tooltiptext {
    width: 300px;
    right: -140px;
  }
}
