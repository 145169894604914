@import './../../styles/settings';

.formField {
  position: relative;
  margin: 0;
}

.formLabel {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 22px;
  color: $text-light-color;
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  margin-left: 15px;
  transform: translate(0, -50%);
  transition: all 0.4s ease-out;
}

.customField {
  display: block;
  width: 100%;
  line-height: 25px;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  color: $text-dark-color;
  font-size: 1.6rem;
  padding: 8px 16px;
  border: 1px solid $gray-200-color;
  box-shadow: none;
  font-weight: 400;

  &::placeholder {
    opacity: 0;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    color: $text-dark-color;
    box-shadow: 0 0 0 1px rgb($primary-color, 1);
  }
}

.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}

.customField:focus ~ .formLabel,
.customField:not(:placeholder-shown) ~ .formLabel {
  top: 8px;
  font-size: 10px;
}

.showHidePassword {
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 0;
  transform: translate(0, -50%);
  border: none;

  &:focus {
    box-shadow: none;
  }
}

// Password Requirements Styles
.requirementsContainer {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid $gray-200-color;
}

.requirementsTitle {
  font-size: 1.4rem;
  font-weight: 400;
  color: $text-dark-color;
  margin-bottom: 8px;
}

.requirementsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.requirement {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 1.4rem;

  span {
    line-height: 1.4;
  }
}

.valid {
  color: $success-color;
  .checkIcon {
    color: $success-color;
    margin-top: 2px;
  }
}

.invalid {
  color: $danger-color;
  .xIcon {
    color: $danger-color;
    margin-top: 2px;
  }
}
