@import 'src/styles/settings';

.verifyContent {
  border-radius: 10px;
  padding: 40px 65px 55px 65px;
  background: $primary-color-50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  top: 83%;
}

.linkColour {
  color: $tertiary-color-500 !important;
  &:hover {
    text-decoration: underline;
    text-underline: $tertiary-color-500;
  }
}
.linkLine {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  justify-content: center;
  top: 180%;
}
.mainContent {
  position: relative;
  z-index: 999;
}
.resendBtn {
  border: none;
}
.widthBtn {
  width: fit-content;
}
@include respondTo($lg) {
  .linkLine {
    top: 50rem;
    justify-content: start;
  }
  .verifyContent {
    width: 70vw;
    top: 74%;
  }
  .modal {
    //width: 700px;
    margin-top: 0;
  }
}
