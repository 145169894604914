@import 'src/styles/settings';

.content {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inputWidth {
  width: 29vw;
}

.greenBorderBottom {
  border-bottom: 2px solid $success-color;
  padding-bottom: 15px;
}

.borderRight {
  border-right: 1px solid $gray-200-color;
  padding-right: 10px;
}
.fontColor {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: 190px !important;
  height: 38px;
}
.contentBox {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 12px;
}
.widthButton {
  width: fit-content;
  border: 1px solid $primary-color !important;
  background-color: $primary-color-50 !important;
}
.saveBtn {
  width: fit-content !important;
}
.listDot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: $primary-color;
}
.bottomContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 2px solid $light-color;
}
.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 7px;
}
.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
  padding: 5px;
  @include respondTo($md) {
    padding: 10px 30px;
  }
}
.tagDelete {
  margin-left: 10px;
}
.green {
  border-radius: 10px;
  border: 1px solid $success-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}
.assessmentResult {
  border-radius: 10px;
  border: 1px solid $success-color;
  background: $success-color-50;
  padding: 24px 32px;
  display: none;
}
.assessmentResultMobile {
  border-radius: 10px;
  border: 1px solid $success-color;
  background: $success-color-50;
  padding: 12px;
  margin-top: 20px;
}
.borderBottom {
  border-bottom: 2px solid $light-color;
  padding-bottom: 28px;
}
.educationCard {
  border-radius: 10px;
  border: 1px solid $border-color;
  padding: 32px 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  //width: 585px;
}
.addBtn {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: 150px !important;
  height: 38px;
}
.assestBtn {
  color: $gray-color !important;
  border: 1px solid $tertiary-color-100 !important;
  background: transparent !important;
  margin-top: 20px;
}
.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.tagBox {
  width: 100%;
  //display: flex;
  margin-top: 50px;
}
.resumeUploadBox {
  padding: 24px 32px;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid $border-color;
}
.referenceInside {
  @extend .resumeUploadBox;
  padding: 16px;
}
.outlinedBtn {
  background-color: $primary-color-50 !important;
}
.selectedResume {
  border: 1px solid $primary-color-200;
  border-radius: 10px;
  background: $primary-color-50;
  padding: 16px;
  margin-top: 16px;
}
.borderTop {
  border-top: 1px solid $primary-color;
  padding-top: 10px;
}
.link {
  color: $primary-color;
  cursor: pointer;
  width: 30%;
}
.elipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 185px;
}
.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.2rem;
  line-height: 1;
}
.searchBar {
  width: 100%;
}
.profilePic {
  width: 120px;
  height: 120px;
  position: relative;
  object-fit: cover;
  border-radius: 20%;
  overflow: hidden;
}
.whiteBtn {
  // display: none !important;
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  padding: 10px 5px;
}
.profileOverview {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 20px;
  height: fit-content;
  overflow: hidden;
}
.organizationsFilter {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 24px 20px;
  width: 413px;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
}
.orgSelection {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  &:focus {
    border: 1px solid $almost-black; // set the border color when the div is selected
  }
  &:hover {
    border: 1px solid $almost-black;
  }
  border-left: none !important;
  margin-top: 40px;
  padding: 10px 18px 10px 12px;
}
.selected {
  border: 1px solid $almost-black; // set the border color when the div is selected
}
.yupError {
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  color: #cd2e00;
  // margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}
@include respondTo($lg) {
  .job {
    width: 25vw;
  }
}
@include respondTo($xl) {
  .searchBar {
    width: 60%;
  }

  .bottomContent {
    flex-direction: row;
  }
  .addButtonFixWidth {
    width: 250px;
  }

  .content {
    flex-direction: row;
    padding: 70px;
  }
  .leftContent {
    width: 70%;
  }
  .rightContent {
    width: 27%;
  }
  .contentBox {
    padding: 24px 32px 40px 32px;
  }
  .addBtn {
    width: 190px !important;
  }
  .assessmentResult {
    display: block;
  }
  .assessmentResultMobile {
    display: none;
  }
}

.widthButton {
  width: fit-content;
}

// div border whitish and cornered width responsive
.skillsCard {
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 24px 32px;
  margin-top: 24px;
  width: 100%;
}

.noBorder {
  border: none;
  outline: none;
}

.width100 {
  width: 100%;
}

.marginRight {
  margin-right: 20px;
}
