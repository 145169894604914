/** color variables */
/** add the colors to the variables.module.scss file also, to extend the colors to the TS **/
$primary-color: #00adbc;
$primary-color-200: #a5d8df;
$primary-color-100: #c3e5e9;
$primary-color-50: #f0f8fa;
$secondary-color: #ff495c;
$special-color: #ff8812;
$gray-color: #636363;
$text-light-color: #9ca3af;
$border-color: #e2e2e2;
$text-dark-color: #1f1f1f;
$gray-200-color: #d9d9d9;
$info-50-color: #ebf0fc;
$info-color: #1d4fc3;
$almost-black: #081120;
$tertiary-color-500: #003d71;
$tertiary-color-100: #cedcf2;
$light-color: #f2f2f2;
$success-color: #00732e;
$success-color-50: #e6f7ed;
$light-primary-color: #f2f7fa;
$danger-color: #cd2e00;
$danger-color-50: #faeae6;
$orange-color: #f98f12;
$orange-color-50: #fef4e7;
$warning-color: #b1660d;
$border-secondary-color: #1b40791f;
$disabled-color: #d3d3d3;
$gray-300-color: #a6a6a6;
$white: #ffffff;
$black: #000000;
$neutralColor: #d1d5db;

/* Extra small devices (phones, 600px and down) */
$xs: 320px;
/* Small devices (portrait tablets and large phones, 600px and up) */
$sm: 600px;
/* Medium devices (landscape tablets, 768px and up) */
$md: 768px;
/* Large devices (laptops/desktops, 992px and up) */
$lg: 992px;
/* Extra large devices (large laptops and desktops, 1200px and up) */
$xl: 1200px;
/* 2K, 4K devices (large laptops and desktops, 2500px and up) */
$xxl: 2500px;

@mixin respondTo($brack-point) {
  @media only screen and (min-width: $brack-point) {
    @content;
  }
}

@mixin respondUpTo($break-point) {
  @media only screen and (max-width: $break-point) {
    @content;
  }
}

/**  color mixins */
@mixin filled($color) {
  border: 3px solid $color;
  background-color: $color;
  color: $white;

  &:hover {
    border-color: darken($color, 10);
    background-color: darken($color, 10);
    color: $white;
  }

  // &:focus,
  // &:active
  // {
  //   border-color: darken($color, 20);
  //   background-color: darken($color, 20);
  //   color: $white;
  //   box-shadow: 0 0 1px 3px rgba($primary-color, 0.6);
  // }
  //  &:focus,
  // &:active {
  //   box-shadow: none !important;
  // }

  &:disabled {
    background-color: $disabled-color;
    pointer-events: none;
    border-color: $disabled-color;
  }
}

@mixin signInBtn($color) {
  border: 3px solid $color;
  background-color: $color;
  color: $white;

  &:hover {
    border-color: darken($color, 20);
    background-color: darken($color, 20);
    color: $white;
    box-shadow: 0 0 1px 3px rgba($primary-color, 0.6);
  }
}

@mixin outlined($color) {
  border: 3px solid $color;
  background-color: $primary-color-50;
  color: $color;

  //&:hover {
  //  border-color: darken($color, 10);
  //  background-color: darken($color, 10);
  //  color: $white;
  //}

  &:focus,
  &:active {
    border-color: darken($color, 20);
    background-color: darken($color, 20);
    color: $white;
    box-shadow: 0 0 1px 1px rgba($primary-color, 0.2);
  }

  &:disabled {
    background-color: transparent;
    pointer-events: none;
    border-color: $disabled-color;
  }
}

@mixin outlinedLoading($color) {
  background-color: transparent !important;
  border: 3px solid lighten($color, 10) !important;
  pointer-events: none !important;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
@mixin loading($color) {
  background-color: lighten($color, 10) !important;
  border: 3px solid lighten($color, 10) !important;
  pointer-events: none !important;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
