@import '/src/styles/settings';

.customButton {
  display: flex;
  flex-direction: row;
  color: $white;
  height: 100%;
  width: 100%;
  align-items: center !important;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Ubuntu';
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  -moz-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  -ms-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  -o-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  //box-shadow: 0 0 25px 0 rgba($secondary-color, 0.3);

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10%;
    top: -1px;
    position: relative;
  }

  .focus {
    outline: none;
    box-shadow: none;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  &Text {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin: 0 !important;
  }
  &SubText {
    font-size: 10px;
    font-weight: 400;
  }

  .withIcon {
    position: relative;
    top: 2px;
  }
}

.flip {
  flex-direction: row-reverse;

  .customButtonIcon {
    margin-left: 10%;
    margin-right: 0;
  }
}
.buttonText {
  display: inline-block;
}
.widthBtn {
  width: 200px;
  @extend .customButton;
  color: $black;
}
/** ndefault buttons */
.primary {
  @include filled($primary-color);
}

.secondary {
  @include filled($secondary-color);
}

.special {
  @include filled($special-color);
}

.danger {
  @include filled($danger-color);
}

.success {
  @include filled($success-color);
}

.transparent {
  @include filled($primary-color-50);
}

/** outlined buttons */
.primaryOutlined {
  @include outlined($primary-color);
}

.secondaryOutlined {
  @include outlined($secondary-color);
}

.specialOutlined {
  @include outlined($special-color);
}

.dangerOutlined {
  @include outlined($danger-color);
}

.successOutlined {
  @include outlined($success-color);
}

.transparentOutlined {
  @include outlined($light-color);
}

/** default buttons loading */
.primaryLoading {
  @include loading($primary-color);
}

.secondaryLoading {
  @include loading($secondary-color);
}

.specialLoading {
  @include loading($special-color);
}

.dangerLoading {
  @include loading($danger-color);
}

.successLoading {
  @include loading($success-color);
}

.grayLoading {
  @include loading($light-color);
}

/** outlined buttons loading */
.primaryLoadingOutline {
  @include outlinedLoading($primary-color);
}

.secondaryLoadingOutline {
  @include outlinedLoading($secondary-color);
}

.specialLoadingOutline {
  @include outlinedLoading($special-color);
}

.dangerLoadingOutline {
  @include outlinedLoading($danger-color);
}

.successLoadingOutline {
  @include outlinedLoading($success-color);
}

.grayLoadingOutline {
  @include outlinedLoading($light-color);
}
