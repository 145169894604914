@import 'src/styles/settings';

.header {
  border-bottom: 1px solid $light-color;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.logoImage {
  height: 75px;
}
.indicator {
  width: 100%;
  height: 1px;
  background-color: $tertiary-color-500;
  left: 0;
}
.logo {
  padding-left: 50px;
  display: none;
}
.textFormat {
  color: $tertiary-color-500;
}
.selected {
  text-underline: darken($tertiary-color-500, 1) !important;
}
.dropDown {
  width: 125px;
}

.signInBtn {
  width: 152px;
}
.sideMenuButton {
  display: none;
}
.mobileMenu {
  width: 60px;
  border-left: 1px solid $gray-200-color;
}
@include respondTo($lg) {
  .header {
    margin: 0 50px;
  }
  .mobileMenu {
    display: none;
  }
  .sideMenuButton {
    display: flex;
  }
  .dropDown {
    width: 225px;
  }
  .mobileLogo {
    display: none;
  }
  .logo {
    display: block;
  }
}
