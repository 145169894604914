@import 'src/styles/settings';
.textFiled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid $gray-200-color;
  padding: 8px 16px;
}
.iconButton {
  border: none !important;
  background: transparent !important;
}
.textColor {
  color: $gray-color !important;
  font-weight: 400 !important;
}
.title {
  color: #6b7280 !important;
}
.arrowBox {
  display: flex;
  flex-direction: column;
}
