@import '../../styles/settings';
.contentAnalysis {
  padding: 20px;
}
.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  margin-top: 16px;
  margin-left: 20px;
  margin-right: 20px;
}
.organizationsFilter {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 24px 20px;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
}
.content {
  padding: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.orgSelection {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  &:focus {
    border: 1px solid $almost-black; // set the border color when the div is selected
  }
  &:hover {
    border: 1px solid $almost-black;
  }
  border-left: none !important;
  margin-top: 40px;
  padding: 10px 18px 10px 12px;
}

.settingsSelected {
  background-color: $primary-color-50;
  border: 2px solid $primary-color;
  color: $almost-black;
}
.settingsTitle {
  color: $gray-300-color;
  text-align: center;
  &:hover {
    color: $almost-black;
  }
  &:focus {
    color: $almost-black;
  }
}
.settingsTitleSelected {
  color: $almost-black;
}
.rightSide {
  width: 100%;
}
.settingsSelection {
  @extend .orgSelection;
  border-right: none !important;
  border-top: none !important;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px !important;
  padding: 12px 32px;
  border: 1px solid $gray-200-color;
  &:focus {
    background-color: $primary-color-50;
    border: 2px solid $primary-color; // set the border color when the div is selected
    color: $almost-black;
  }
  &:hover {
    background-color: $primary-color-50;
    border: 2px solid $primary-color;
    color: $almost-black;
  }
  cursor: pointer;
}
@include respondTo($xl) {
  .organizationsFilter {
    margin-top: 0;
    width: 350px;
  }
  .content {
    padding: 70px;
    width: 80%;
    flex-direction: row;
  }
  .contentAnalysis {
    padding: 35px 70px 70px 70px;
    //width: 80%;
  }
}
.widthButton {
  width: fit-content;
}
.noResize {
  resize: none;
}
.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}
