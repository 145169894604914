@import './../../../styles/settings';
.textFiled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.uploadFiled {
  width: 100%;
  padding: 8px 16px;
  border: 1px solid $border-color;
  border-radius: 10px;
  cursor: pointer;
}
