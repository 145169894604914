@import 'src/styles/settings';

.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}
.widthButton {
  width: fit-content;
}
.textarea.form-control {
  font-size: 16px !important;
}
.OutlinedBtn {
  color: $text-dark-color !important;
  border: 3px solid $primary-color-50 !important;
  background: none !important;
  width: fit-content !important;
}
.greenBox {
  border-radius: 10px;
  border: 1px solid $success-color;
  display: flex;
  background: $success-color-50;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}
.yellowBox {
  border-radius: 10px;
  background: $orange-color-50;
  display: flex;
  justify-content: space-between;
  border: 1px solid $warning-color;
  padding: 24px 32px;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@include respondTo($lg) {
  //.modal{
  //  display: block;
  //
  //}
}
